import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';

import { connect, useDispatch, useSelector } from 'react-redux';

import dynamic from 'next/dynamic';

import _get from 'lodash/get';
import _isEmpty from 'lodash/isEmpty';
import _isEqual from 'lodash/isEqual';
import uniqBy from 'lodash/uniqBy';

import { useDevice } from '@bonnet/core/device';

import { eventBus } from '@atc/cai-event-bus';
import { mapDispatchToActionProp } from '@atc/modular-redux';

import { calculateDownPayment, formattedNumber, getFirstPartyVistorId, getPixallId } from 'atc-js';

import {
    LoadingSpinner,
    ModalScrollPanel,
    Panel,
    StickyContainer,
} from 'reaxl';
import { sendClick, sendImpressions } from 'reaxl-analytics';
import { useBrand } from 'reaxl-brand';
import { useFeatures } from 'reaxl-features';
import { OwnerChatEvents } from 'reaxl-molecules';
import { AvailableOffers, DigitalRetailOverlayCTA, getAppliedDealerSettings, getIncentives, transformIncentives } from 'reaxl-wallet';

import { fallbackInterestRates } from 'axl-config';

import {
    UDS_CONFIG_COMPLETED,
    UDS_CONFIG_REQUEST,
} from 'wallet-manager';

import remoteActions from '@/actions/vdp/remoteActions';

import {
    isVDPExperienceUTM,
    isVDPReengagementExperienceUTM,
} from '@/analytics/analyticsUtils';

import {
    activeInteractionDuck,
    authDuck,
    birfDuck,
    currentPageNameDuck,
    dealsDuck,
    globalConfigsDuck,
    interestRateDuck,
    inventoryDuck,
    paymentsDuck,
    queryDuck,
    userDuck,
} from '@/ducks';

import { srpResultsDuck } from '@/ducks/srp';
import {
    vdpActiveEmailListingDuck,
    vdpActiveInteractionDuck,
    vdpAvailableOffersDuck,
    vdpResultsDuck,
} from '@/ducks/vdp';

import EmailOwnerFetcher from '@/fetchers/EmailOwnerFetcher';
import ExportToDealXgFetcher from '@/fetchers/ExportToDealXgFetcher';
import PaymentIncentivesFetcher from '@/fetchers/PaymentIncentivesFetcher';
import PersistDealDataService from '@/fetchers/vdp/PersistDealDataService';
import PsxPrequalEligibilityCheckFetcher from '@/fetchers/vdp/PsxPrequalEligibilityCheckFetcher';

import useLeadAdditionalInventory from '@/hooks/useLeadAdditionalInventory';
import useVdpNavigation from '@/hooks/useVdpNavigation';

import EmailFormNextStepsContainer from '@/containers/EmailFormNextStepsContainer';

import Anchor from '@/components/Anchor/index.js';
import LazyComponent from '@/components/LazyComponent';
import AffixedSidebar from '@/components/vehicledetails/AffixedSidebar';
import OfferSummary from '@/components/vehicledetails/OfferSummary';

import LeaderBoardAdSlotContainer from './LeaderBoardAdSlotContainer';
import ListingErrorContainer from './ListingErrorContainer';
import MediaGallery from './MediaGallery';
import PricingSnapshotContainer from './PricingSnapshotContainer';
import PrimaryVdpCallsToActionContainer from './PrimaryVdpCallsToActionContainer';
import PurchaseWithConfidenceSnapshotContainer from './PurchaseWithConfidenceSnapshotContainer';
import SaveAndShareContainer from './SaveAndShareContainer';
import ShowcaseAdSlotContainer from './ShowcaseAdSlotContainer';
import SpecificationsGridContainer from './SpecificationsGridContainer';
import StandaloneCreditAppContainer from './StandaloneCreditAppContainer';
import VdpAlertsContainer from './VdpAlertsContainer';
import VdpPaginationContainer from './VdpPaginationContainer';
import VehicleDetailsEmailModalContainer from './VehicleDetailsEmailModalContainer';
import VehicleDetailsTitleContainer from './VehicleDetailsTitleContainer';
// TODO: cleanup/ resolve lazy v non lazy container at conclusion of tabbed_specifications test
import VehicleFeaturesContainer from './VehicleFeaturesContainer';

// Ads Containers

// Hybrid Vdp Navigation

export const AdditionalResourcesContainer = dynamic(() => import('@/containers/vdp/AdditionalResourcesContainer'), { ssr: false });
export const AdditionalVehicleDetailsContainer = dynamic(() => import('@/containers/vdp/AdditionalVehicleDetailsContainer'), { ssr: false });
export const CertifiedProgramOfferContainer = dynamic(() => import('@/containers/vdp/CertifiedProgramOfferContainer'), { ssr: false });
export const FraudReportContainer = dynamic(() => import('@/containers/FraudReportContainer'), { ssr: false });
export const HomeServicesContainer = dynamic(() => import('@/containers/vdp/HomeServicesContainer'), { ssr: false });
export const MyWalletContainer = dynamic(() => import('@/containers/vdp/MyWalletContainer'), { ssr: false });
export const OwnerDetailsContainer = dynamic(() => import('@/containers/vdp/OwnerDetailsContainer'), { ssr: false });
export const PreorderSearchContainer = dynamic(() => import('@/containers/vdp/PreorderSearchContainer'), { ssr: false });
export const PreQualCTAContainer = dynamic(() => import('@/containers/vdp/PreQualCTAContainer'), { ssr: false });
export const PricingDetailsContainer = dynamic(() => import('@/containers/vdp/PricingDetailsContainer'), { ssr: false });
export const PurchaseWithConfidenceContainer = dynamic(() => import('@/containers/vdp/PurchaseWithConfidenceContainer'), { ssr: false });
export const RatingsAndReviewsContainer = dynamic(() => import('@/containers/vdp//RatingsAndReviews/RatingsAndReviewsContainer'), { ssr: false });
export const StickyHeaderContainer = dynamic(() => import('@/containers/vdp/StickyHeaderContainer'), { ssr: false });
export const VehicleExchangeContainer = dynamic(() => import('@/containers/vdp/VehicleExchangeContainer'), { ssr: false });
export const VehicleFeaturesContainerLazy = dynamic(() => import('@/containers/vdp/VehicleFeaturesContainer'), { ssr: false });

// Default
const DealerLeadsContainer = dynamic(() => import('./DealerLeadsContainer'), { ssr: false });

// Hybrid
const VdpPaidLandingContainer = dynamic(() => import('./VdpPaidLandingContainer'), { ssr: false });

// PSX
const PsxCtaContainer = dynamic(() => import('@/containers/vdp/psx/PsxCtaContainer'), { ssr: false });
const PsxLeadsContainer = dynamic(() => import('@/containers/vdp/psx/PsxLeadsContainer'), { ssr: false });
const PsxExplainerContainer = dynamic(() => import('@/containers/vdp/psx/PsxExplainerContainer'), { ssr: false });
const PsxFraudContainer = dynamic(() => import('@/containers/vdp/psx/PsxFraudContainer'), { ssr: false });
const PsxPrimerContainer = dynamic(() => import('@/containers/vdp/psx/PsxPrimerContainer'), { ssr: false });
const PsxVerificationContainer = dynamic(() => import('@/containers/vdp/psx/PsxVerificationContainer'), { ssr: false });
const SimilarInventoryContainer = dynamic(() => import('@/containers/vdp/SimilarInventoryContainer'), { ssr: false });

/**
 * !xs:
 * `-----------------------------`
 * |    Sticky Header (<lg)      |
 * |.............................|
 * |         Navigation          |
 * |.............................|
 * | Banner                      |
 * |.............................|
 * |                  |          |
 * | Media            | Sidebar  |
 * | Upper Details    |          |
 * |.............................|
 * |              AD             |
 * |.............................|
 * | Lower Details               |
 * |-----------------------------|
 * | Support Containers (hidden) |
 *  ----------------------------
 *
 * xs:
 * `------------------`
 * |  Sticky Header   |
 * |..................|
 * |  Navigation      |
 * |..................|
 * |  Banner          |
 * |..................|
 * |  Media           |
 * |..................|
 * |  Sidebar         |
 * |..................|
 * |  Upper Details   |
 * |..................|
 * |        AD        |
 * |..................|
 * |  Lower Details   |
 * |------------------|
 * | Support (hidden) |
 *  ------------------
 *
 * Default Banner Contents
 * 1. Listing Error
 * 2. Title Container
 *
 * Default Media Contents
 * 1. Media Gallery
 * 2. Specifications
 *
 * Default Sidebar Contents
 * 1. Pricing Snapshot
 * 2. Prequal
 * 3. Esntial
 * 4. Owner Details Snapshot
 * 5. Email Form
 * 6. Ad
 *
 * Default Upper Details Contents
 * 1. Wallet / NDS
 * 2. Seller Comments
 * 3. Vehicle Features
 * 4. Price Details
 * 5. Certified Pre-Owned
 * 6. Purchase With Confidence
 *
 * Default Lower Details Contents
 * 1. Ratings and Reviews
 * 2. Owner Details
 * 3. Similar Vehicles
 * 4. At Home Services
 * 5. Additional Resources
 * 6. Fraud Report
 *
 * Default Support Containers
 * 1. Email Modal
 * 2. Third Party Chat Events
 * 3. Stand Alone Credit App
 *
**/

// Should probably clean this up so we don't have to add to this every time
const thirdPartyCertTypes = ['FORD_BLUE_CERT', 'FORD_EV_CERT', 'FORD_CERT', 'NISSAN_SELECT_CERT', 'INFINITI_SELECT_CERT', 'CARBRAVO_OFF_CERT', 'CARBRAVO_OFF_BUDGET_CERT'];

const AFFIX_ID = 'affixedBottomBoundary';

export function VehicleDetailsContainer({
    actions: {
        setActiveInteraction = () => { },
        setActiveEmailListingResults = () => { },
        updateMyWallet = () => { },
        getAvailableOffers = () => { },
        updateListingIncentives = () => { },
    },
}) {
    // Feature Flags
    const {
        accelerate: [, {
            credit_app: enableSDPCreditApp,
        }],
        additional_resources: [isAdditionalResourcesEnabled],
        ads: [isAdsEnabled, { mma: isMmaEnabled, mrec: isMrecEnabled }],
        brand: [, { brandName }],
        chat: [isChatEnabled, { providers: chatProviders }],
        consumer_ratings: [isConsumerRatingsEnabled, { vdp_ratings: isConsumerRatingsVdpEnabled }],
        hybrid_vdp_new_carousel_position: [enableNewVdpLayout],
        my_wallet: [, { enable_deals: enableDeals }],
        nds_calc: [isNDSCalcEnabled],
        nds_lead_form_redesign: [enableNdsLeadFormRedesign],
        nds_right_rail: [enableNDSRightRail],
        similar_inventory: [isSimilarInventoryEnabled],
        standalone_credit_app: [isStandaloneCreditAppEnabled],
        combined_specs_features: [isCombinedSpecsEnabled],
        hybrid_vdp_marketing_reengagement: [enableHybridVdpMarketingReengagement],
        psx_owner_snapshot: [isPsxOwnerSnapshot],
        enable_deals_duck: [isDealsDuckEnabled],
        sticky_header_prequal_cta: [isStickyHeaderPrequalCTA],
    } = useFeatures([
        'accelerate',
        'additional_resources',
        'ads',
        'brand',
        'chat',
        'consumer_ratings',
        'hybrid_vdp_new_carousel_position',
        'my_wallet',
        'nds_calc',
        'nds_lead_form_redesign',
        'nds_right_rail',
        'similar_inventory',
        'standalone_credit_app',
        'combined_specs_features',
        'hybrid_vdp_marketing_reengagement',
        'psx_owner_snapshot',
        'enable_deals_duck',
        'sticky_header_prequal_cta',
    ]);

    const device = useDevice();
    const dispatch = useDispatch();
    const isXs = _get(device, 'is.xs', false);
    const isGreaterThanSm = _get(device, 'greaterThan.sm', false);
    const isLg = _get(device, 'is.lg', false);
    const { brands, isBrand } = useBrand();
    const isKbbBranded = isBrand(brands.KBB_BRAND);
    const isAtcBranded = isBrand(brands.ATC_BRAND);

    // Get data from Redux
    // dealsDuck: code clean up
    const selectorDuck = isDealsDuckEnabled ? dealsDuck : paymentsDuck;
    const creditAppHost = useSelector(globalConfigsDuck.selectors.getCreditAppHost);
    const listing = useSelector(vdpResultsDuck.selectors.getVdpActiveInventory);
    const birf = useSelector(birfDuck.selectors.getPageBirf);
    const isLoggedIn = useSelector(authDuck.selectors.isLoggedIn);
    const query = useSelector(queryDuck.selectors.getDuckState) || {};
    const utmApplyVDPExperience = isVDPExperienceUTM(query);
    const utmReengagementApplyVDPExperience = isVDPReengagementExperienceUTM(query);
    const displayHybridVdp = useSelector(vdpActiveInteractionDuck.selectors.getDisplayHybridVdp);
    const showEmailModal = useSelector(vdpActiveInteractionDuck.selectors.getShowEmailModal);
    const showEmailConfirmationModal = useSelector(vdpActiveInteractionDuck.selectors.getShowEmailConfirmationModal);
    const { availableOffers } = useSelector(vdpAvailableOffersDuck.selectors.getDuckState);
    const deal = useSelector(vdpResultsDuck.selectors.getDealInfo, _isEqual);
    const listingIncentives = useSelector(paymentsDuck.selectors.getListingIncentives) || {};
    const pencilingPayments = useSelector(paymentsDuck.selectors.getPencilingPayments) || {};
    const listingPayments = useSelector(paymentsDuck.selectors.getListingPayments) || {};
    const { incentiveIds } = useSelector((state) => paymentsDuck.selectors.getLowestPencilingPaymentByListingId(state, listing?.id));
    const existingDeal = useSelector((state) => selectorDuck.selectors.getDealByListingVin(state, listing?.vin)) || {};
    const payments = useSelector(paymentsDuck.selectors.getPaymentsInfo);
    const interestRates = useSelector((state) => state?.interestRates) || fallbackInterestRates;
    // hybrid VDP navigation // TODO: can we use/create new duck selectors for hybrid vdp?  YES!!!
    const srpListingIds = useSelector(srpResultsDuck.selectors.getActiveResults);
    const paginationListingIds = srpListingIds.map((id) => ({ listingId: id, clickType: 'listing' })).filter(Boolean);
    const inventory = useSelector(inventoryDuck.selectors.selectHydratedInventory) || {};
    const zip = useSelector(userDuck.selectors.getZip) || query?.zip;
    const listingIds = uniqBy(paginationListingIds, 'listingId');
    const { dealType, interactions = {} } = payments;
    const { paymentEmphasis } = interactions;

    // Used for handling email input field interaction analytics tagging
    const emailInputClickedRef = useRef(null);

    /**
     * Initialize the Wallet Configuration Manager
     */
    const [config, setConfig] = useState({});

    const updateConfiguration = useCallback((data) => {
        // override config with FF info
        data.isSingleColumn = enableNDSRightRail && !isXs;

        setConfig(data);
    }, [enableNDSRightRail, isXs]);

    // Send config request with data
    useEffect(() => {
        eventBus.emit(UDS_CONFIG_REQUEST, {
            buildConfig: true,
            listing,
            payments,
            interestRates,
        });

        // Listen for completed response to update local state
        eventBus.on(UDS_CONFIG_COMPLETED, updateConfiguration);

        return () => {
            eventBus.off(UDS_CONFIG_COMPLETED, updateConfiguration);
        };
    }, [payments, interestRates, listing, updateConfiguration]);

    // destructure listing and declare variables
    const {
        certifiedProduct = {},
        dealerConfig = {},
        hasVehicleExchange,
        id: listingId,
        hasSpecialOffer,
        isWestlakePrequal = false,
        listingType,
        kbbConsumerReviewCount,
        kbbVehicleId,
        make,
        model,
        owner = {},
        pricingDetail = {},
        type,
        year,
        vin,
        paymentServices = {},
    } = listing;

    const { customOrderStatus: { newCarCustomOrder, newCarBoost } = {}, dealerPreferredCTA, privateSeller: isPrivateSeller, sacaMmdId, suppressRatingsReviews, textNumber, id: ownerId } = owner;

    const showRatingsAndReviews = !suppressRatingsReviews && isConsumerRatingsEnabled && isConsumerRatingsVdpEnabled && kbbVehicleId && kbbConsumerReviewCount >= 3;

    const showPreorderCard = newCarCustomOrder || newCarBoost;
    const getAdditionalInventory = useLeadAdditionalInventory(true, ownerId);

    const isHybridVdpMarketingReengagement = enableHybridVdpMarketingReengagement && utmReengagementApplyVDPExperience;

    const { incentivesUrl, digitalRetailingType, dealerSettings = {} } = paymentServices;

    // pricing & payments
    const { incentive = '', salePrice = '', msrp = '' } = pricingDetail;
    const hasPrice = !!(salePrice || msrp);
    const price = salePrice || msrp;

    // Wallet
    const enableStandaloneCreditApp = isStandaloneCreditAppEnabled && !!sacaMmdId && !!creditAppHost;

    useEffect(() => {
        if ((isHybridVdpMarketingReengagement || utmApplyVDPExperience) && !isPrivateSeller && !displayHybridVdp) {
            setActiveInteraction({
                displayHybridVdp: true,
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isHybridVdpMarketingReengagement, utmApplyVDPExperience, setActiveInteraction]);

    useEffect(() => {
        sendImpressions({ name: 'dealerSegmentationImpression' });

        if (!isPrivateSeller && window.location.hash === '#showEmailModal') {
            setActiveInteraction({
                showEmailModal: true,
            });
        }

        // Reset the ref when visiting another VDP
        emailInputClickedRef.current = false;
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [listingId]);

    useEffect(() => {
        if (isPrivateSeller && zip && vin) {
            PsxPrequalEligibilityCheckFetcher({ zip })
                .then(({ userLocationIsFinanceEligible }) => {
                    if (userLocationIsFinanceEligible) {
                        dispatch(inventoryDuck.creators.updateInventoryItem(listingId, {
                            creditApp: {
                                url: `/marketplace/financing?vin=${vin}`,
                                partner: 'psx',
                                ctaLabel: 'Get Pre-Qualified',
                            },
                        }));
                    }
                });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [zip, vin]);

    const getIncentiveOffers = useCallback(async () => {
        const incentivesParams = { activeTab: dealType, zip };
        const incentives = await getIncentives({
            incentivesFetcher: PaymentIncentivesFetcher,
            incentivesUrl,
            incentivesParams,
        });
        updateListingIncentives({
            [listingId]: {
                [dealType]: incentives[0]?.hasError ? incentives : transformIncentives(incentives, incentiveIds),
            },
        });
    }, [dealType, zip, incentivesUrl, updateListingIncentives, listingId, incentiveIds]);

    const hasIncentivesOffers = !!incentivesUrl && (pencilingPayments[listingId]?.expectAvailableOffers || listingPayments[listingId]?.expectAvailableOffers);
    const enabledAmdIncentivesOffers = !hasSpecialOffer && hasIncentivesOffers;

    const {
        creditType,
        defaultLeaseDownPayment,
        downPayment,
        downPaymentPercent,
    } = getAppliedDealerSettings({
        dealerSettings,
        walletInteractions: interactions,
        price,
        payments,
        nationalRates: interestRates,
    });

    const hasExistingDeal = enableDeals && !_isEmpty(existingDeal);

    const handleOpenSDP = () => setActiveInteraction({
        emailCTAContext: '',
        showEmailModal: false,
    });

    const handleErrorOpenSDP = () => setActiveInteraction({
        emailCTAContext: 'calculator',
        showEmailModal: true,
    });

    const handleEditSettingsClick = () => {
        dispatch(vdpActiveInteractionDuck.creators.setKeys({
            showEmailModal: true,
            showEditEmailModal: true,
        }));
    };

    const paymentsInfo = {
        ...payments,
        creditType,
        downPayment: downPayment || calculateDownPayment(price, { downPayment, downPaymentPercent }),
        dueAtSigning: defaultLeaseDownPayment || calculateDownPayment(price, {
            downPayment: paymentEmphasis ? downPayment : defaultLeaseDownPayment,
            downPaymentPercent: paymentEmphasis ? downPaymentPercent : 0.10,
        }),
    };

    const renderDigitalRetailOverlayCTA = (
        <DigitalRetailOverlayCTA
            listedPrice={price}
            listing={listing}
            paymentsInfo={paymentsInfo}
            paymentsDuck={paymentsDuck}
            onClick={handleOpenSDP}
            onError={handleErrorOpenSDP}
            uiContext="incentives"
        />
    );

    const isAccelerate = digitalRetailingType === 'accelerate';

    const prequalCtaProps = {
        enableSDPCreditApp: enableSDPCreditApp && isAccelerate,
        enableSACAStickyHeader: enableStandaloneCreditApp && !isAccelerate,
        handleErrorOpenSDP,
        listedPrice: price,
        paymentsInfo,
        existingDeal,
    };

    const onEmailCTAClick = useCallback((context) => {
        if (context === 'incentives' && hasSpecialOffer) {
            getAvailableOffers({ id: listingId });
        }
        if (context === 'incentives' && enabledAmdIncentivesOffers) {
            getIncentiveOffers();
        }
        setActiveInteraction({
            emailCTAContext: context,
            showEmailModal: true,
        });
        setActiveEmailListingResults([listingId]);
    }, [hasSpecialOffer, enabledAmdIncentivesOffers, setActiveInteraction, setActiveEmailListingResults, listingId, getAvailableOffers, getIncentiveOffers]);

    // checks that user is in hybrid VDP experiment and has LNX code in URL
    const showHybridVdp = (displayHybridVdp || displayHybridVdp === null && (isHybridVdpMarketingReengagement || utmApplyVDPExperience));
    // hybrid VDP Nav
    const [activeIndex, setActiveIndex] = useState(listingIds.findIndex((item) => item?.listingId === listing?.id));
    useEffect(() => {
        if (showHybridVdp !== false) {
            setActiveIndex(listingIds.findIndex((item) => item?.listingId === listingId));
        }
    }, [listingId, listingIds, showHybridVdp]);

    const { firstRecord = 0, numRecords = 25 } = query;

    const navigateToVdp = useVdpNavigation();

    const paginationUrlBuilder = useCallback((params) => {
        const pathname = query[0];
        const searchParams = new URLSearchParams();
        Object.entries(params).forEach(([key, value]) => {
            searchParams.set(key, String(value));
        });

        return pathname + '?' + searchParams.toString();
    }, [query]);

    const handleHybridVdpNavigation = useCallback(() => {
        const pageCount = firstRecord / numRecords;
        const listingIndex = listingIds[activeIndex + 1];

        navigateToVdp({
            url: paginationUrlBuilder({
                listingId: listingIndex?.listingId,
                clickType: listingIndex?.clickType || 'listing',
                firstRecord: (numRecords * pageCount),
                numRecords,
            }),
            listingId: listingIndex?.listingId,
        });

    }, [firstRecord, numRecords, listingIds, activeIndex, navigateToVdp, paginationUrlBuilder]);

    const nextListingId = listingIds[activeIndex + 1]?.listingId;
    const nextVehicle = inventory[nextListingId];

    const showNDSRightRail = enableNDSRightRail && isGreaterThanSm;
    // enable Westlake Prequal CTA in mobile state
    const enableMobileWestlakeCTA = isWestlakePrequal && isXs;
    // dealerConfig
    const { hideAds } = dealerConfig;

    // certifiedProduct
    const { id: certifiedType } = certifiedProduct;

    // ads
    let showMrecMobile = false;
    let showMma = false;
    if (isAdsEnabled && !hideAds) {
        showMrecMobile = isMrecEnabled && isXs;
        showMma = isMmaEnabled && (isAtcBranded && !isXs || isKbbBranded && isLg);
    }

    // chat
    const isChatEventEnabled = isChatEnabled && chatProviders.includes(owner?.chat?.provider ?? '');

    // Incentives
    const isIncentivesOfferEnabled = listingType?.toLowerCase() === 'new' && (hasSpecialOffer || enabledAmdIncentivesOffers);

    const isNdsLeadFormRedesignEnabled = enableNdsLeadFormRedesign && !isAccelerate;

    const pixallFirstPartyId = getFirstPartyVistorId();
    const pixallThirdPartyId = getPixallId() || 'nl';

    const fireTelmetricsTag = useCallback((phoneNumber) => {
        const data = {
            birf,
            listing,
            phoneNumber,
        };
        sendClick('telMetricsPhoneTextClick', {}, data);
    }, [listing, birf]);

    const persistDealData = useCallback(() => {
        if (isNDSCalcEnabled && enableDeals && Object.keys(deal).length) {
            PersistDealDataService({
                listingId,
                pixallId: pixallFirstPartyId || pixallThirdPartyId,
                dealData: deal,
            });
        }
    }, [listingId, pixallFirstPartyId, pixallThirdPartyId, isNDSCalcEnabled, enableDeals, deal]);

    const phoneAndTextHandler = useCallback((phoneNumber) => {
        fireTelmetricsTag(phoneNumber);
        persistDealData();
    }, [fireTelmetricsTag, persistDealData]);

    const textBody = `Hi, I'm interested in your ${year} ${make?.name ?? make} ${model?.name ?? model} listed on ${brandName}${incentive || hasPrice ? ' for ' + formattedNumber({
        value: incentive || salePrice || msrp,
        style: 'currency',
        currency: 'USD',
    }) : ''}`;

    const handleEmailConfirmation = useCallback(() => {
        setActiveInteraction({
            showEmailConfirmationModal: !showEmailConfirmationModal,
        });
    }, [setActiveInteraction, showEmailConfirmationModal]);

    const handleEmailInputInteraction = useCallback((event, context = '') => {
        // Should fire once per VDP when user interact with email input form for the first time
        if (!emailInputClickedRef.current) {
            sendClick('emailFormEngagementClick', event, { inventoryId: listing.id, uiContext: context });
            emailInputClickedRef.current = true;
        }
    }, [listing.id]);

    const emailOwnerProps = useMemo(() => ({
        activeEmailListingDuck: vdpActiveEmailListingDuck,
        alternateFormContent: (
            <DigitalRetailOverlayCTA
                listing={listing}
                paymentsDuck={paymentsDuck}
                uiContext="testDrive"
            />
        ),
        enableConsumerMetadata: true,
        currentPageNameDuck,
        dealXgFetcher: ExportToDealXgFetcher,
        handleEmailInputInteraction,
        fetcher: EmailOwnerFetcher,
        interestRateDuck,
        isLoggedIn,
        nextStepsProps: {
            getAdditionalInventory,
            show: showEmailConfirmationModal,
            toggle: handleEmailConfirmation,
            nextSteps: <EmailFormNextStepsContainer />,
        },
        ownerId: owner?.id,
        paymentsDuck,
        ...(isDealsDuckEnabled && {
            dealsDuck,
        }),
        updateMyWallet,
        userDuck,
        handleEditSettingsClick,
        activeInteractionDuck,
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }), [updateMyWallet, isLoggedIn, owner.id, showEmailConfirmationModal, showEmailModal, handleEmailConfirmation, getAdditionalInventory, listing]);

    const emailCTAProps = useMemo(() => ({
        onClick: onEmailCTAClick,
        listingId,
        buttonStyle: dealerPreferredCTA === 'WEBSITE' ? 'secondary' : 'primary',
    }), [onEmailCTAClick, listingId, dealerPreferredCTA]);

    // setting LazyLoad preserve heights
    let purchaseConfidenceHeight;
    let ndsHeight;
    let pricingHeight;
    let psxComponentsHeight;
    switch (device?.mediaType) {
        case 'lg':
            ndsHeight = showNDSRightRail ? 1013 : 895;
            pricingHeight = 884;
            break;
        case 'sm':
            purchaseConfidenceHeight = 225;
            ndsHeight = 919;
            psxComponentsHeight = 515;
            break;
        case 'xs':
            purchaseConfidenceHeight = 225;
            ndsHeight = 1267;
            psxComponentsHeight = 480;
            break;
        case 'md':
        default:
            purchaseConfidenceHeight = 244;
            ndsHeight = showNDSRightRail ? 1082 : 954;
            pricingHeight = 872;
            psxComponentsHeight = 430;
    }

    /**
     * STICKY HEADER
     */
    const sectionStickyHeader = useMemo(() => {
        if (isPrivateSeller) {
            return (
                <LazyComponent>
                    <StickyHeaderContainer />
                </LazyComponent>
            );
        }
        return (
            <LazyComponent>
                <StickyHeaderContainer
                    activeIndex={activeIndex}
                    emailCTAProps={emailCTAProps}
                    phoneClickHandler={phoneAndTextHandler}
                    handleHybridVdpNavigation={handleHybridVdpNavigation}
                    nextVehicle={nextVehicle}
                    {...(isStickyHeaderPrequalCTA && { prequalCtaProps })}
                />
            </LazyComponent>
        );
    }, [isPrivateSeller, activeIndex, emailCTAProps, phoneAndTextHandler, handleHybridVdpNavigation, nextVehicle, isStickyHeaderPrequalCTA]);

    /**
     * NAVIGATION
     */
    const sectionNavigation = useMemo(() => {
        if (showHybridVdp) {
            return [
                <VdpPaginationContainer key="hybrid_pagination" />,
                <LazyComponent
                    renderOnScroll={false}
                    key="paid_landing"
                >
                    <VdpPaidLandingContainer
                        emailCTAProps={emailCTAProps}
                        phoneAndTextHandler={phoneAndTextHandler}
                        emailOwnerProps={emailOwnerProps}
                        persistDealData={persistDealData}
                    />
                </LazyComponent>,
            ];
        }

        return [
            <VdpPaginationContainer key="pagination" />,
        ];
    }, [showHybridVdp, emailCTAProps, phoneAndTextHandler, emailOwnerProps, persistDealData]);

    /**
     * BANNER
     */
    const sectionBanner = useMemo(() => {
        if (showHybridVdp && enableNewVdpLayout && isSimilarInventoryEnabled) {
            return <LazyComponent key="psx_similar_inventory"><SimilarInventoryContainer /></LazyComponent>;
        }
        if (showHybridVdp) { return null; }
        if (isPrivateSeller) {
            return [
                <ListingErrorContainer key="psx_error" />,
                <VehicleDetailsTitleContainer
                    key="psx_title"
                    showRatingsAndReviews={showRatingsAndReviews}
                    onEmailCTAClick={onEmailCTAClick}
                />,
                <div
                    style={{ minHeight: '45px' }}
                    key="verification"
                >
                    <LazyComponent key="verification">
                        <PsxVerificationContainer key="verification" />
                    </LazyComponent>
                </div>,
            ];
        }
        return [
            <ListingErrorContainer key="error" />,
            <VdpAlertsContainer key="vdp_Alerts" />,
            <VehicleDetailsTitleContainer
                key="title"
                showRatingsAndReviews={showRatingsAndReviews}
                onEmailCTAClick={onEmailCTAClick}
            />,
        ];
    }, [showHybridVdp, isPrivateSeller, showRatingsAndReviews, enableNewVdpLayout, isSimilarInventoryEnabled, onEmailCTAClick]);

    /**
     * MEDIA
     */
    const sectionMedia = useMemo(() => {
        if (showHybridVdp) {
            return null;
        }

        return [
            <MediaGallery
                key="media"
                emailCTAProps={emailCTAProps}
            />,
            isXs && (
                <SaveAndShareContainer key="mobile_save" />
            ),
            !isXs && isCombinedSpecsEnabled && <VehicleFeaturesContainer key="tabbed_specs" />,
            !isXs && !isCombinedSpecsEnabled && <SpecificationsGridContainer key="specs" />,
        ];
    }, [showHybridVdp, emailCTAProps, isXs, isCombinedSpecsEnabled]);

    /**
     * SIDEBAR
     */
    const sectionSidebar = useMemo(() => {
        if (isPrivateSeller) {
            return [
                <PricingSnapshotContainer key="pricing_prequal" />,

                isXs && (
                    <LazyComponent
                        fallback={<LoadingSpinner height={40} />}
                        key="psx_cta_container"
                    >
                        <PsxCtaContainer
                            layout="makeOffer"
                            className="margin-top-4 margin-bottom-5"
                            minHeight={40}
                        />
                    </LazyComponent>
                ),

                isXs && isCombinedSpecsEnabled && <VehicleFeaturesContainer key="psx_tabbed_specs_mobile" />,
                isXs && !isCombinedSpecsEnabled && <SpecificationsGridContainer key="psx_specs_mobile" />,
                showNDSRightRail && (
                    <LazyComponent
                        key="nds_right_rail"
                        fallback={(
                            <LoadingSpinner
                                // required for jumplink
                                id="nativeDealContainer"
                                height={ndsHeight}
                            />
                        )}
                    >
                        <Panel>
                            <MyWalletContainer
                                onEmailCTAClick={onEmailCTAClick}
                                enableCreditAppLink={enableStandaloneCreditApp}
                                isRightRail
                                config={config}
                            />
                        </Panel>
                    </LazyComponent>
                ),
                <AffixedSidebar
                    id={AFFIX_ID}
                    affixedGreaterThan="sm"
                    key="psx_sidebar"
                >
                    <LazyComponent
                        key="psx_sidebar"
                        renderOnScroll={false}
                        fallback={<LoadingSpinner height={psxComponentsHeight} />}
                    >
                        {!isPsxOwnerSnapshot && <PsxLeadsContainer />}
                        <PsxPrimerContainer listingId={listingId} />
                        {isPsxOwnerSnapshot && <PsxLeadsContainer />}
                    </LazyComponent>
                </AffixedSidebar>,
            ];
        }

        let sharedComponents = [
            <PurchaseWithConfidenceSnapshotContainer key="pwc-snapshot" />,
            showNDSRightRail && (
                <LazyComponent
                    key="nds_right_rail"
                    fallback={(
                        <LoadingSpinner
                            // required for jumplink
                            id="nativeDealContainer"
                            height={ndsHeight}
                        />
                    )}
                >
                    <Panel
                        bsStyle=""
                    >
                        <MyWalletContainer
                            onEmailCTAClick={onEmailCTAClick}
                            enableCreditAppLink={enableStandaloneCreditApp}
                            isRightRail
                            config={config}
                        />
                    </Panel>
                </LazyComponent>
            ),
        ];
        if (!showHybridVdp) {
            sharedComponents = [
                <PricingSnapshotContainer
                    key="pricing_prequal"
                    emailCTAProps={isIncentivesOfferEnabled ? emailCTAProps : undefined}
                />,
                isXs && (
                    <PrimaryVdpCallsToActionContainer
                        emailCTAProps={{
                            ...emailCTAProps,
                            labelKey: 'checkAvailability',
                        }}
                    />
                ),
                !enableMobileWestlakeCTA && (
                    <LazyComponent
                        key="prequal_cta_container"
                        fallback={null}
                    >
                        <PreQualCTAContainer
                            uiContext="simplified"
                            emailCTAProps={emailCTAProps}

                        />
                    </LazyComponent>
                ),
                isXs && isCombinedSpecsEnabled && <VehicleFeaturesContainer key="tabbed_specs_mobile" />,
                isXs && !isCombinedSpecsEnabled && <SpecificationsGridContainer key="specs_mobile" />,
                ...sharedComponents,
            ];
        }
        if (!isXs) {
            sharedComponents = [
                ...sharedComponents,
                <PrimaryVdpCallsToActionContainer
                    emailCTAProps={emailCTAProps}
                    key="primary-vdp-cta"
                />,
            ];
        }
        return [
            ...sharedComponents,
            <AffixedSidebar
                id={AFFIX_ID}
                affixedGreaterThan="sm"
                key="sidebar"
            >
                <LazyComponent
                    renderOnScroll={false}
                    key="sidebar"
                >

                    <DealerLeadsContainer
                        adOnly={showHybridVdp && enableNewVdpLayout}
                        phoneAndTextHandler={phoneAndTextHandler}
                        textBody={textBody}
                        emailCTAProps={emailCTAProps}
                        emailOwnerProps={emailOwnerProps}
                        persistDealData={persistDealData}
                    />
                </LazyComponent>

            </AffixedSidebar>,
        ];
    }, [isPrivateSeller, showNDSRightRail, ndsHeight, onEmailCTAClick, enableStandaloneCreditApp, showHybridVdp, enableNewVdpLayout, phoneAndTextHandler, textBody, emailCTAProps, emailOwnerProps, persistDealData, isXs, isCombinedSpecsEnabled, isIncentivesOfferEnabled, enableMobileWestlakeCTA, config, psxComponentsHeight, isPsxOwnerSnapshot]);
    const { disablePriceBreakdown } = config;

    /**
     * UPPER DETAILS
     */
    const sectionUpperDetails = useMemo(() => {

        if (isPrivateSeller) {
            return [
                hasPrice && !showNDSRightRail
                && (
                    <LazyComponent
                        key="psx_nds"
                        fallback={(
                            <LoadingSpinner
                                // required for jumplink
                                id="nativeDealContainer"
                                height={ndsHeight}
                            />
                        )}
                    >
                        <MyWalletContainer
                            onEmailCTAClick={onEmailCTAClick}
                            enableCreditAppLink={enableStandaloneCreditApp}
                            config={config}
                        />
                    </LazyComponent>
                ),
                <LazyComponent key="psx_vehicle_details"><AdditionalVehicleDetailsContainer /></LazyComponent>,
                <LazyComponent
                    renderOnScroll={false}
                    key="psx_lazy_features"
                >
                    {(!isCombinedSpecsEnabled || showHybridVdp) && <VehicleFeaturesContainerLazy showHybridVdp={showHybridVdp} />}
                </LazyComponent>,
                showRatingsAndReviews && (
                    <LazyComponent
                        key="psx_ratings_reviews_lazy"
                        fallback={<Anchor id="rating-reviews-section" />}
                    >
                        <RatingsAndReviewsContainer
                            key="ratings_reviews"
                            isInColumn={isPrivateSeller}
                        />
                    </LazyComponent>
                ),
                <LazyComponent
                    key="psx_pwc_container"
                    fallback={(
                        <LoadingSpinner id="purchaseConfidence" />
                    )}
                >
                    <PurchaseWithConfidenceContainer />
                </LazyComponent>,
            ];
        }
        return [
            hasPrice && !showNDSRightRail
            && (
                <LazyComponent
                    key="nds"
                    fallback={(
                        <LoadingSpinner
                            // required for jumplink
                            id="nativeDealContainer"
                            height={ndsHeight}
                        />
                    )}
                >
                    <MyWalletContainer
                        onEmailCTAClick={onEmailCTAClick}
                        enableCreditAppLink={enableStandaloneCreditApp}
                        config={config}
                    />
                </LazyComponent>
            ),
            <LazyComponent key="vehicle_details"><AdditionalVehicleDetailsContainer /></LazyComponent>,
            <LazyComponent
                renderOnScroll={false}
                fallback={null}
                key="lazy_features"
            >
                {(!isCombinedSpecsEnabled || showHybridVdp) && <VehicleFeaturesContainerLazy showHybridVdp={showHybridVdp} />}
            </LazyComponent>,
            <LazyComponent
                fallback={(
                    <LoadingSpinner
                        height={pricingHeight}
                        id="pricingSection"
                    />
                )}
                key="pricing_details"
            >
                <PricingDetailsContainer
                    emailCTAProps={emailCTAProps}
                    disablePriceBreakdown={disablePriceBreakdown}
                />
            </LazyComponent>,
            (type === 'Certified' || thirdPartyCertTypes.includes(certifiedType)) && (
                <LazyComponent
                    key="certified"
                    fallback={<Anchor id="warrantySection" />}
                >
                    <CertifiedProgramOfferContainer />
                </LazyComponent>
            ),
            <LazyComponent
                key="pwc_container"
                fallback={(
                    <LoadingSpinner
                        height={purchaseConfidenceHeight}
                        id="purchaseConfidence"
                    />
                )}
            >
                <PurchaseWithConfidenceContainer
                    emailCTAProps={emailCTAProps}
                    onEmailCTAClick={onEmailCTAClick}
                />
            </LazyComponent>,
        ];
    }, [isPrivateSeller, hasPrice, showNDSRightRail, ndsHeight, onEmailCTAClick, enableStandaloneCreditApp, isCombinedSpecsEnabled, showHybridVdp, pricingHeight, emailCTAProps, type, certifiedType, purchaseConfidenceHeight, config, disablePriceBreakdown, showRatingsAndReviews]);

    /**
     * LOWER DETAILS
     */
    const sectionLowerDetails = useMemo(() => {

        if (isPrivateSeller) {
            return [
                <LazyComponent
                    fallback={<div id="psx-faq" />}
                    key="psx_explainer"
                >
                    <PsxExplainerContainer listingId={listingId} />
                </LazyComponent>,
                isSimilarInventoryEnabled && <LazyComponent key="psx_similar_inventory"><SimilarInventoryContainer /></LazyComponent>,
                <LazyComponent key="psx_fraud"><PsxFraudContainer /></LazyComponent>,
            ];
        }

        return [
            <LazyComponent key="ads_lazy">
                <>
                    {showMma && <LeaderBoardAdSlotContainer />}
                    {showMrecMobile && <ShowcaseAdSlotContainer wrap />}
                </>
            </LazyComponent>,
            showRatingsAndReviews && (
                <LazyComponent
                    key="ratings_reviews_lazy"
                    fallback={<Anchor id="rating-reviews-section" />}
                >
                    <RatingsAndReviewsContainer
                        key="ratings_reviews"
                        isInColumn={isPrivateSeller}
                    />
                </LazyComponent>
            ),
            <LazyComponent key="owner_details_lazy">
                <OwnerDetailsContainer
                    phoneClickHandler={phoneAndTextHandler}
                    textClickHandler={phoneAndTextHandler}
                    onEmailCTAClick={onEmailCTAClick}
                    textBody={textNumber ? textBody : undefined}
                    key="owner_details"
                />
            </LazyComponent>,
            hasVehicleExchange && (
                <LazyComponent key="vehicle_exchange">
                    <VehicleExchangeContainer />
                </LazyComponent>
            ),
            showPreorderCard && <LazyComponent key="preorder_lazy"><PreorderSearchContainer /></LazyComponent>,
            <LazyComponent
                fallback={<Anchor id="homeServices" />}
                key="ahs"
            >
                <HomeServicesContainer
                    onEmailCTAClick={onEmailCTAClick}
                    listingId={listingId}
                />
            </LazyComponent>,
            isAdditionalResourcesEnabled && <LazyComponent key="addl_resources"><AdditionalResourcesContainer /></LazyComponent>,
            <LazyComponent key="fraud"><FraudReportContainer /></LazyComponent>,
        ];
    }, [isPrivateSeller, showRatingsAndReviews, isSimilarInventoryEnabled, showMma, showMrecMobile, phoneAndTextHandler, onEmailCTAClick, textNumber, hasVehicleExchange, listingId, isAdditionalResourcesEnabled, textBody, showPreorderCard]);

    /**
     * SUPPORT CONTAINERS
     */
    const sectionSupportContainers = useMemo(() => {
        if (isPrivateSeller) {
            return null;
        }
        return [
            <VehicleDetailsEmailModalContainer
                {...emailOwnerProps}
                {...(isIncentivesOfferEnabled && {
                    incentivesProps: {
                        additionalContent: (
                            <ModalScrollPanel>
                                <AvailableOffers
                                    offers={availableOffers || listingIncentives[listingId]?.[dealType]}
                                    wrappedInModal
                                />
                            </ModalScrollPanel>
                        ),
                        sideBarToModalTitle: 'Available Offers',
                        uiContext: 'split',
                        nextStepsProps: {
                            ...emailOwnerProps.nextStepsProps,
                        },
                        ...(enabledAmdIncentivesOffers && !hasExistingDeal && {
                            footerContent: renderDigitalRetailOverlayCTA,
                            buttonStyle: 'secondary',
                        }),
                    },
                })}
                {...(isNdsLeadFormRedesignEnabled && {
                    ndsOfferBreakdownProps: {
                        additionalContent: (
                            <OfferSummary
                                listing={listing}
                                config={config}
                            />
                        ),
                        sideBarToModalTitle: 'Send Offer',
                    },
                })}
                key="email_modal"
            />,
            isChatEventEnabled && (
                <OwnerChatEvents
                    listing={listing}
                    key="chat_events"
                    chatCallBack={persistDealData}
                />
            ),
            enableStandaloneCreditApp && (
                <StandaloneCreditAppContainer
                    creditAppHost={creditAppHost}
                    key="saca_modal"
                />
            ),
        ];
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isPrivateSeller, emailOwnerProps, listing, enableStandaloneCreditApp, creditAppHost, isChatEventEnabled, persistDealData, availableOffers, isIncentivesOfferEnabled, listingIncentives, isNdsLeadFormRedesignEnabled, config, dealType, listingId, enabledAmdIncentivesOffers, hasExistingDeal]);

    /**
     * This return block should only contain the basic layout of the VDP
     * any new containers or components should be added
     * to the appropriate sections above.
     */
    return (
        <>
            {sectionStickyHeader}
            <StickyContainer>
                {sectionNavigation}
                <div className="container">
                    {sectionBanner}
                    <div className="row">
                        {/* Any components in these sections should NOT be
                          * wrapped in a PageSection or .container
                          */}
                        <div className="col-xs-12 col-sm-7 col-md-8">
                            {sectionMedia}
                            {isGreaterThanSm && sectionUpperDetails}
                        </div>
                        <div className="col-xs-12 col-sm-5 col-md-4 padding-left-md-3">
                            {sectionSidebar}
                        </div>
                        {!isGreaterThanSm && (
                            <div className="col-xs-12">
                                {sectionUpperDetails}
                            </div>
                        )}
                    </div>
                </div>
                <Anchor id={AFFIX_ID} />
                {/* Any components in this section SHOULD be
                * wrapped in a PageSection for proper spacing
                * unless a new .row / .col-* layout is introduced
                */}
                {sectionLowerDetails}
            </StickyContainer>
            {sectionSupportContainers}
        </>
    );
}

const mapDispatchToProps = mapDispatchToActionProp({
    setActiveEmailListingResults: vdpActiveEmailListingDuck.creators.setActiveResults,
    setActiveInteraction: vdpActiveInteractionDuck.creators.setKeys,
    updateMyWallet: paymentsDuck.creators.updateMyWallet,
    getAvailableOffers: remoteActions.getAvailableOffers,
    updateListingIncentives: paymentsDuck.creators.updateListingIncentives,
});

export default connect(null, mapDispatchToProps)(React.memo(VehicleDetailsContainer, _isEqual));
